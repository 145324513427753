import React from "react"
import "./modal.less"

class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.modalRef = React.createRef();
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(e) {
    if (!this.modalRef) {
      return; 
    }
    if (this.modalRef.current.contains(e.target)) {
      return;
    }
    const { onClose } = this.props;
    onClose && onClose();
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false)
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false)
  }
  render() {
    const { children, onClose, topContent } = this.props
    return (
      <div className="modal-outer">
        <div className="modal-inner" ref={this.modalRef}>
        {topContent && <div className="modal-top">
          {topContent}
        </div> }
        <div className="modal-bottom">
          <i
            className="fa fa-times"
            onClick={onClose}
            aria-hidden="true"
            style={{
              padding: "5px",
              position: "absolute",
              top: "5px",
              right: "5px",
              cursor: "pointer",
              color: "#666",
            }}
          ></i>
          {children}
          </div>
        </div>
      </div>
    )
  }
}

export default Modal
