import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

// import { appUrl } from "../helpers/helpers"
// import devicesImage from "../../images/devices.png"
import groundsightSensor from "../../images/groundsight-sensor.png"
import groundsightLogo from "../../images/groundsight-white-final.png"
import icebergLettuce from "../../images/iceberg-lettuce.png"
import iphone from "../../images/iphone.png"
import bellicon from "../../images/bellicon.png"
import "./internet-of-things.less"
import { Link } from "gatsby"
import Modal from "../../components/modal";
import InterestForm from '../../components/interestform';

const NewForGrowersPage = () => {

  const [modelOpen, showModal] = useState(false);
  return <Layout headerColor="#e74447" whiteLogo={true}>
    {modelOpen ? <Modal topContent={"Please share your contact information so we can get you started!"} onClose={()=>{showModal(false)}}><InterestForm/></Modal> : null}
    <SEO title="Grow with GroundSight™" />
    <div className="news">
      Sostena, FarmRaise strategically align to make USDA Finance programs more accessible. <Link to="/press-release">Click here to learn more.</Link>
    </div>
    <div id="for-growers-shippers">
      <section id="intro">
        <div class="container">
          <div class="row valign-wrapper">
            <div class="col m6 s12 valign">
              <img src={groundsightLogo} alt="GroundSight™" style={{
                width: '85%',
                margin: '0 8%',
              }} />
              <h2>Meet your new best friend.</h2>
              <p>
              Use our cellular connected moisture sensing hardware to control water stress on
                your row crops, fruits and veggies. Purchase the system as a
                service or buy the sensors individually.
              </p>
              <div
                to="contact"
                onClick={()=> showModal(true)}
                className="btn btn-rounded btn-shadowless orange"
              >
                Get Started Today
              </div>
              {"  or  "}
              <Link to="/contact" className="white-text underline">
                Contact Sales
              </Link>
            </div>
            <div class="col m6 s12 valign">
              <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}><iframe src="https://player.vimeo.com/video/418261993?color=e74447&title=0&byline=0" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
            </div>
          </div>
        </div>
      </section>

      <section id="groundsight-device">
        <div className="container">
          <div class="row valign-wrapper">
            <div class="col m6 s12 valign">
              <img src={groundsightSensor} alt="GroundSight™" />
            </div>
            <div class="col m6 s12 valign">
              <h3 id="withbell"><img id="bellicon" src={bellicon} /> Crop stress control technology using real time data*</h3>
              <p>View commodity specific weather data, heat units real time and forecasted, changing harvest estimates</p>
              <ul>
                <li>Get rain alerts and other forecasted weather data to adjust your plan accordingly.</li>
                <li>It’s cloudy and your hybrid is setting, know this when you are out of town.</li>
                <li>See soil moisture by phenological stage to reduce crop stress and keep your yield goals on plan.</li>
              </ul>
              <small>*Cellular connected -- LTE, 4G, 3G, 2G capability</small>
            </div>
          </div>
         
        </div>
      </section>
      <img src={icebergLettuce} alt="Lettuce Stages" />
      <section id="devices">
        <div class="container">
          <div class="row valign-wrapper">
            <div class="col s12 m7 valign">
              <h3>Cutting edge technology that’s crop specific.</h3>
              <p>Visualize your data by crop type, by phenological stage. The sensors update
every 10 minutes so you get real time visibility into your rootzone uptake.</p>
              <ul>
                <li>Set custom alerts for yourself or your employees - by crop stage - via text message or email.</li>
                <li>Get access to our pre-approved crop guides, technical support team and regional field experts.</li>
                <li>Maximize your yield potential and protect your investment using our proven, precision ag product.</li>
              </ul>
            </div>
            <div class="col s12 m5 valign">
              <div class="iphone-wrapper">
              <img src={iphone} alt="SMS" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="cta">
        <Link
          to={`/contact`}
          className="btn btn-green btn-shadowless btn-rounded orange"
        >
          Get Started
        </Link>
      </section>
    </div>
  </Layout>
}


// const OldForGrowersPage = () => (
//   <Layout>
//     <SEO title="Crop Development Planning" />
//     <div className="container">
//       <div className="row">
//         <div className="col s8" id="forGrowers-left">
//           <img src={devicesImage} alt="Features For Growers" />
//         </div>
//         <div className="col s4">
//           <div id="grower-features">
//             <div className="grower-feature">
//               <h4>Plan &amp; grow your produce crop</h4>
//               <p>
//                 Make an agronomically sound planting schedule. Use our digital
//                 tools to get real time alerts and data visualization.
//               </p>
//               <a href={`${appUrl}/start/grower`}>Learn More</a>
//             </div>
//             <div className="grower-feature">
//               <h4>Get private quotes &amp; contracts</h4>
//               <p>
//                 Get quotes and contracts for crop inputs, freight, financing and
//                 produce sales. Connect with other growers, shippers & processors
//                 on a pre or post harvest basis and make a plan that works for
//                 both parties.
//               </p>
//               <a href={`${appUrl}/start/grower`}>Learn More</a>
//             </div>
//             <div className="grower-feature">
//               <h4>Use our proprietary products</h4>
//               <p>
//                 We’ve developed some pretty awesome agronomic tools as well. You
//                 don’t have to use them, but we are pretty passionate about how
//                 they can help your company. From genetics to ag technology,
//                 we’re always looking to the future.
//               </p>
//               <a href={`${appUrl}/start/grower`}>Learn More</a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>

//   </Layout>
// )

export default NewForGrowersPage
