import React from "react"
import { Form, Text } from "informed"
import "./interestform.less"
import { ddp } from "../api/ddp"


const notEmpty = value => {
  return !value ? "Required Field" : undefined
}

class InterestForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      submitted: false,
      error: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(doc) {
    const methodId = ddp.method("produceplan/interestForm", [doc])
    this.setState({ submitting: true })
    ddp.on("result", message => {
      if (message.id === methodId) {
        this.setState({ submitting: false })
        if (message.error) {
          this.setState({
            error: true,
          })
        } else {
          this.setState({
            submitted: true,
          })
        }
      }
    })
  }
  render() {
      const { submitted, submitting, error } = this.state;
    return (
      <div className="" id="interest-form-modal">
        {submitting && <div className="modal-message">Submitting...</div>}
        {submitted && <div className="modal-message">Thank you! Someone will reach out soon</div> }
        {error && <div className="modal-message">Oops! Something went wrong. Please reach out to support@produceplan.com</div>}
        {(!submitting && !submitted) && 
        
        <>
        <Form onSubmit={this.handleSubmit}>
          {({ formState }) => (
            <>
              <div className="row">
                <div style={{ color: "red" }}>{formState.errors.full_name}</div>
                <label htmlFor="full_name">Full Name</label>
                <br />
                <Text field="full_name" placeholder="" validate={notEmpty} />
              </div>
              <div className="row">
                <div style={{ color: "red" }}>{formState.errors.email}</div>
                <label htmlFor="email">Email</label> <br />
                <Text field="email" placeholder="" validate={notEmpty} />
              </div>
              <div className="row">
                <div style={{ color: "red" }}>
                  {formState.errors.phone_number}
                </div>
                <label htmlFor="phone_number">Phone Number</label> <br />
                <Text field="phone_number" placeholder="" validate={notEmpty} />
              </div>
              <div className="row">
                <button
                  type="submit"
                  className="btn btn-rounded btn-shadowless"
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </Form>
        </>
        }
      </div>
    )
  }
}

export default InterestForm
